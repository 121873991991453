import { Button, Col, Form, Row, Input, Divider, Alert, DatePicker, Select, Table, Modal } from 'antd';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Constants } from 'common/constants';
import {
  GetTransactionStatusDetails,
  TransactionActions,
  TransactionSelector,
  UpdateToDeliver,
  UpdateTransactionStatusOutForDelivery,
} from '@features/Transaction';
import dayjs from 'dayjs'; // Use dayjs instead of moment for consistency
import { useEffect, useState } from 'react';
import { BarcodeRequestModel } from 'common/models';
import { PATHS } from '@routing/routes';
import 'moment/locale/en-gb'; // Import locale to ensure consistent formatting
import { commonSelector } from '@features/Common/store/commonSelector';
import { GetCodeSets } from '@features/Common';
import TextArea from 'antd/es/input/TextArea';
import { TransactionStatusDetailsViewModel } from 'common/models/TransactionStatus';
import moment from 'moment';
export const Delivered = () => {
  const transactionState = useSelector(TransactionSelector);
  const commonState = useSelector(commonSelector);

  const history = useHistory();
  const dispatch = useDispatch();
  const convertToDayjs = (date) => {
    return date ? dayjs(date) : null;
  };

  const [formTransaction] = Form.useForm();
  const [formDetails] = Form.useForm();

  const [error, setError] = useState('');
  const [errorDetails, setErrorDetails] = useState('');
  const [success, setSuccess] = useState(false);
  const [selectedOutForDelivery, setSelectedOutForDelivery] = useState(
    transactionState.TransactionDelivery?.Barcode[0].OutForDelivery
  );
  const [selectedETADeliveryDate, setSelectedETADeliveryDate] = useState(
    transactionState.TransactionDelivery?.Barcode[0].ETADeliveryDate
  );
  const [selectedDeliveredDate, setSelectedDeliveredDate] = useState(
    transactionState.TransactionDelivery?.Barcode[0].DeliveredDate
  );
  const onFinish = async (value: BarcodeRequestModel) => {
    setError('');
    setSuccess(false);
    if (transactionState.SelectedTransId) {
      value.TransactionsId = transactionState.SelectedTransId;
    } else {
      setError(Constants.KFSBOX_GenericError);
      return;
    }
    if ((!value.OutForDelivery || !value.ETADeliveryDate || !value.DeliveredDate) && value.Receiver) {
      setError('All fields are required.');
      return;
    }

    if ((!value.OutForDelivery || !value.ETADeliveryDate || !value.Receiver) && value.DeliveredDate) {
      setError('All fields are required.');
      return;
    }

    if (!value.OutForDelivery && !value.ETADeliveryDate && value.DeliveredDate && value.Receiver) {
      setError('Out for delivery and ETA receiver are required');
      return;
    }
    setError('');

    if (!value.OutForDelivery) {
      value.OutForDelivery = null;
    } else {
      // console.log('xxx', selectedDate);
      value.OutForDelivery = selectedOutForDelivery;
    }

    if (!value.ETADeliveryDate) {
      value.ETADeliveryDate = null;
    } else {
      value.ETADeliveryDate = selectedETADeliveryDate;
    }

    if (!value.DeliveredDate) {
      value.DeliveredDate = null;
    } else {
      value.DeliveredDate = selectedDeliveredDate;
    }
    await dispatch(UpdateToDeliver(value));
    setSuccess(true);
  };

  const handleDateChangeOutForDelivery = (date, dateString) => {
    if (date) {
      setSelectedOutForDelivery(dateString);
    }
  };

  const handleDateChangeDelivered = (date, dateString) => {
    if (date) {
      setSelectedDeliveredDate(dateString);
    }
  };

  const handleDateChangeETADelivery = (date, dateString) => {
    if (date) {
      setSelectedETADeliveryDate(dateString);
    }
  };

  useEffect(() => {
    if (!commonState.CodeSets) {
      dispatch(GetCodeSets());
    }

    if (transactionState.SelectedTransId) {
      dispatch(GetTransactionStatusDetails(transactionState.SelectedTransId));
    } else {
      setError(Constants.KFSBOX_GenericError);
      return;
    }
    setErrorDetails('');
  }, []);

  const data: TransactionStatusDetailsViewModel[] = transactionState.TransactionStatusDetailsViewModelList || [];
  const [selectedDetailsDate, setSelectedDetailsDate] = useState(
    transactionState.TransactionStatusDetailsViewModel?.Date
  );
  const handleSelectedDetailsDate = (date, dateString) => {
    if (date) {
      setSelectedDetailsDate(dateString);
    }
  };

  const columns = [
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'Type',
      render: (text) => {
        return commonState.CodeSets?.find((x) => x.Category == 'TransStatusType' && x.Code == text)?.Display; // Display description or fallback to the text itself
      },
    },
    {
      title: 'From',
      dataIndex: 'From',
      key: 'From',
      render: (text) => {
        return commonState.CodeSets?.find((x) => x.Category == 'TransStatusDetails' && x.Code == text)?.Display; // Display description or fallback to the text itself
      },
    },
    {
      title: 'To',
      dataIndex: 'To',
      key: 'To',
      render: (text) => {
        return commonState.CodeSets?.find((x) => x.Category == 'TransStatusDetails' && x.Code == text)?.Display; // Display description or fallback to the text itself
      },
    },
    {
      title: 'Remarks',
      dataIndex: 'Remarks',
      key: 'Remarks',
    },
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
      render: (text) => {
        return moment(text).format('YYYY-MM-DD'); // Format the date using moment
      },
    },
    {
      title: 'Action',
      dataIndex: 'Load',
      key: 'Load',
      render: (text, record) => (
        <span>
          <a onClick={() => handleUpdateClick(record)}>Update</a> | <a onClick={() => showConfirm(record)}>Delete</a>
        </span>
      ),
    },
  ];

  const handleUpdateClick = (data: TransactionStatusDetailsViewModel) => {
    dispatch(TransactionActions.SetTransactionStatusDetailsViewModel(data));
    if (data.Date) {
      setSelectedDetailsDate(data.Date);
    }

    formDetails.setFieldsValue({
      Type: data.Type,
      From: data.From,
      To: data.To,
      Remarks: data.Remarks,
      Date: convertToDayjs(data.Date),
    });
  };

  const handleClearClick = () => {
    dispatch(TransactionActions.SetTransactionStatusDetailsViewModel(undefined));
    setErrorDetails('');
    formDetails.resetFields();
  };

  const { confirm } = Modal;

  const showConfirm = (data: TransactionStatusDetailsViewModel) => {
    confirm({
      title: 'Do you want to delete this status?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'No',
      cancelText: 'Yes',
      okButtonProps: { className: 'ant-btn-default-okcancelbooking' },
      cancelButtonProps: { className: 'ant-btn-primary' },
      async onCancel() {
        const updatedData = { ...data, IsDeleted: true };
        await dispatch(UpdateTransactionStatusOutForDelivery(updatedData));
        // await dispatch(GetContainerDetailsByContainerId(container.ContainerId));

        if (transactionState.SelectedTransId) {
          dispatch(GetTransactionStatusDetails(transactionState.SelectedTransId));
        } else {
          setError(Constants.KFSBOX_GenericError);
          return;
        }
      },
      async onOk() {
        // setHideContainerStatusFields(true);
      },
    });
  };

  // transactionState.TransactionDelivery?.Barcode[0].DeliveredDate
  const onFinishDetails = async (value: TransactionStatusDetailsViewModel) => {
    if (transactionState.TransactionStatusDetailsViewModel?.Id) {
      value.Id = transactionState.TransactionStatusDetailsViewModel?.Id;
    }

    const da = transactionState.TransactionStatusDetailsViewModelList?.find(
      (x) => x.From == value.From && x.Type == value.Type && x.To == value.To && x.Id != value.Id
    );

    if (da) {
      setErrorDetails('The Type, From, and To already exist in the records.');
      return;
    }

    if (transactionState.SelectedTransId) {
      value.TransactionsId = transactionState.SelectedTransId;
    } else {
      setError(Constants.KFSBOX_GenericError);
      return;
    }

    if (!value.Date) {
      value.Date = null;
    } else {
      value.Date = selectedDetailsDate;
    }

    value.Status = 'OFD';

    formDetails.resetFields();
    setErrorDetails('');
    dispatch(TransactionActions.SetTransactionStatusDetailsViewModel(undefined));
    await dispatch(UpdateTransactionStatusOutForDelivery(value));
    dispatch(GetTransactionStatusDetails(transactionState.SelectedTransId));
  };

  const [type, setType] = useState(null);
  const handleTypeChange = (value) => {
    setType(value);
  };

  return (
    <div className="App">
      <Form name="senderForm" layout="vertical" form={formTransaction} onFinish={onFinish}>
        <Row align="middle" gutter={[10, 0]}>
          {' '}
          <Col xs={24} sm={24} md={7} lg={6} xl={4}>
            <h1>Update to delivered</h1>
          </Col>
        </Row>
        <Row align="middle" gutter={[10, 0]}>
          <Col xs={24} sm={24} md={7} lg={6} xl={4}>
            <p>
              Reference no.: {transactionState.TransactionDelivery?.Transactions.RefNo} <br /> Original receiver:{' '}
              {transactionState.TransactionDelivery?.Receiver}
              <br /> Barcode:
              {transactionState.TransactionDelivery?.Barcode.map(
                (barcode) => ` ${barcode.Abbreviations1}${barcode.Number}${barcode.Abbreviations2}`
              ).join(', ')}
            </p>
          </Col>
        </Row>
        <Row align="middle" gutter={[10, 0]}>
          <Col xs={24} sm={8} md={7} lg={6} xl={4}>
            <Form.Item
              label={data && data.length > 0 ? 'Warehouse transfer' : 'Out for delivery'} //"Out for delivery"
              name="OutForDelivery"
              initialValue={convertToDayjs(transactionState.TransactionDelivery?.Barcode[0].OutForDelivery) ?? ''}
            >
              <DatePicker
                size="large"
                className="full-width"
                onChange={handleDateChangeOutForDelivery}
                format="YYYY-MM-DD" // Specify the format of the date picker
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={7} lg={6} xl={4}>
            <Form.Item
              label="ETA receiver"
              name="ETADeliveryDate"
              initialValue={convertToDayjs(transactionState.TransactionDelivery?.Barcode[0].ETADeliveryDate) ?? ''}
            >
              <DatePicker
                size="large"
                className="full-width"
                placeholder="ETA receiver"
                onChange={handleDateChangeETADelivery}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle" gutter={[10, 0]}>
          <Col xs={24} sm={24} md={7} lg={6} xl={4}>
            <Form.Item
              label="Date delivered"
              name="DeliveredDate"
              initialValue={convertToDayjs(transactionState.TransactionDelivery?.Barcode[0].DeliveredDate) ?? ''}
            >
              <DatePicker
                size="large"
                className="full-width"
                placeholder="Date delivered"
                onChange={handleDateChangeDelivered}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={7} lg={6} xl={4}>
            <Form.Item
              label="Received by"
              name="Receiver"
              initialValue={transactionState.TransactionDelivery?.Barcode[0].Receiver ?? ''}
              // rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Input size="large" maxLength={25} placeholder="Received by" />
            </Form.Item>
          </Col>
        </Row>
        {error && (
          <>
            <Row align="middle" gutter={[10, 0]}>
              <Col xs={24} sm={24} md={14} lg={12} xl={8}>
                <Alert message={error} type="error" />
              </Col>
            </Row>
            <br />
          </>
        )}

        {success && (
          <>
            <Row align="middle" gutter={[10, 0]}>
              <Col xs={24} sm={24} md={14} lg={12} xl={8}>
                <Alert message="Record saved successfully" type="success" />
              </Col>
            </Row>
            <br />
          </>
        )}
        <Row>
          <Col xs={24} sm={24} md={7} lg={3} xl={3}>
            <Button
              className="prevSenderBtn"
              size="large"
              type="default"
              onClick={() => history.push(PATHS.ContainerLoading.path)}
            >
              {Constants.BTN_BACK}
            </Button>
            <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
              {Constants.BTN_SAVE}
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider />
      <Row align="middle" gutter={[10, 0]}>
        {' '}
        <Col xs={24} sm={24} md={7} lg={6} xl={4}>
          <h1>Out for delivery details</h1>
        </Col>
      </Row>
      <Form name="senderForm1" layout="vertical" form={formDetails} onFinish={onFinishDetails}>
        <Row align="middle" gutter={[10, 0]}>
          <Col xs={24} sm={20} md={12} lg={8} xl={6}>
            <Form.Item
              label="Type"
              name="Type"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
              // initialValue={transactionState.TransactionStatusDetailsViewModel?.Type}
            >
              <Select
                size="large"
                showSearch
                placeholder="Select"
                optionFilterProp="children"
                onChange={handleTypeChange}
              >
                {commonState.CodeSets?.filter((x) => x.Category === 'TransStatusType').map((destination) => (
                  <Select.Option key={destination.Code} value={destination.Code}>
                    {destination.Display}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {type !== 'OFD' && (
            <>
              <Col xs={24} sm={20} md={12} lg={8} xl={6}>
                <Form.Item
                  label="From"
                  name="From"
                  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                  // initialValue={transactionState.TransactionStatusDetailsViewModel?.Details}
                >
                  <Select size="large" showSearch placeholder="Select" optionFilterProp="children">
                    <Select.Option key="" value="">
                      {''}
                    </Select.Option>
                    {commonState.CodeSets?.filter((x) => x.Category === 'TransStatusDetails').map((destination) => (
                      <Select.Option key={destination.Code} value={destination.Code}>
                        {destination.Display}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={20} md={12} lg={8} xl={6}>
                <Form.Item
                  label="To"
                  name="To"
                  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                  // initialValue={transactionState.TransactionStatusDetailsViewModel?.Details}
                >
                  <Select size="large" showSearch placeholder="Select" optionFilterProp="children">
                    <Select.Option key="" value="">
                      {''}
                    </Select.Option>
                    {commonState.CodeSets?.filter((x) => x.Category === 'TransStatusDetails').map((destination) => (
                      <Select.Option key={destination.Code} value={destination.Code}>
                        {destination.Display}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <Row gutter={[10, 0]}>
          <Col xs={24} sm={24} md={7} lg={6} xl={6}>
            <Form.Item label="Date" name="Date" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
              <DatePicker size="large" className="full-width" placeholder="Date" onChange={handleSelectedDetailsDate} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={6}>
            <Form.Item
              label="Remarks"
              name="Remarks"
              // initialValue={transactionState.TransactionStatusDetailsViewModel?.Remarks}
              // rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <TextArea size="large" maxLength={500} rows={2} />
            </Form.Item>
          </Col>
        </Row>
        {errorDetails && (
          <>
            <Row align="middle" gutter={[10, 0]}>
              <Col xs={24} sm={24} md={14} lg={12} xl={8}>
                <Alert message={errorDetails} type="error" />
              </Col>
            </Row>
            <br />
          </>
        )}
        <Row>
          <Col xs={24} sm={24} md={7} lg={3} xl={3}>
            <Button className="prevSenderBtn" size="large" type="default" onClick={() => handleClearClick()}>
              Clear
            </Button>
            <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
              {Constants.BTN_SAVE}
            </Button>
          </Col>
        </Row>
        <br />
        <Table dataSource={data} columns={columns} />
      </Form>
    </div>
  );
};
