import { AutoComplete, Breadcrumb, Button, Col, Form, FormInstance, Input, Row, Select, message } from 'antd';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { AddSender, SenderDetailsActions, SenderDetailsSelector, UpdateSender } from '@features/SenderDetails/';
import { Constants, SenderStatus } from 'common/constants';
import { useEffect, useRef, useState } from 'react';
import { AUStateCodeList, getLocationDetailsByPostcode } from 'common/code/AUPostalCode';
import { OperationStatus } from 'store/rootTypes';
import { TransactionSelector } from '@features/Transaction';
import { SenderModel } from 'common/models/BookingTypes';
import { PATHS } from '@routing/routes';
import { BookingSelector } from '../store/BookingSelector';
import { KFSSignupCustomer } from '@features/Account';
import { commonSelector } from '@features/Common/store/commonSelector';
import { commonAction } from '@features/Common/store/commonSlice';
import { SignUpHearUsCodeList } from 'common/code/DropdownCode';
import TextArea from 'antd/es/input/TextArea';

const { Search } = Input;
export const SenderAddress = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const commonState = useSelector(commonSelector);
  const bookingState = useSelector(BookingSelector);
  const senderDetailsState = useSelector(SenderDetailsSelector);
  const transactionState = useSelector(TransactionSelector);
  const [isForUpdate, setForUpdate] = useState(false);
  const [isForAdd, setForAdd] = useState(false);
  const [suburbError, setSuburbError] = useState(Constants.REQUIRED_FIELD);
  useEffect(() => {
    // console.log(transactionState.TransactionSummary?.Sender.Id);
    if (senderDetailsState.Sender === null || senderDetailsState.Sender === undefined) {
      history.push('/home');
    }
  }, []);

  useEffect(() => {
    const updateSenderAndRedirect = async () => {
      if (senderDetailsState.Sender !== null && senderDetailsState.Sender !== undefined && isForUpdate) {
        if (transactionState?.FromUpdateLink || transactionState?.FromUpdateLinkOrderABox) {
          const data: SenderModel = { ...senderDetailsState.Sender }; // Create a copy of the object
          data.Id = transactionState.TransactionSummary?.Sender?.Id ?? '';
          await dispatch(UpdateSender(data));
        } else {
          await dispatch(UpdateSender(senderDetailsState?.Sender));
        }

        message.success('Sender updated successfully');
        if (commonState.GlobalTransactionId) {
          history.push(PATHS.SenderListingBooking.path);
        } else if (transactionState?.FromUpdateLinkOrderABox && !transactionState.SelectedTransId) {
          history.push(PATHS.SenderListingOrderBox.path);
        } else if (transactionState?.FromUpdateLinkOrderABox) {
          history.push(PATHS.SummaryOrderBox.path);
        } else if (transactionState?.FromUpdateLink) {
          history.push('/transaction/summary');
        } else {
          history.push('/sender/listing');
        }
      }
    };

    updateSenderAndRedirect();
  }, [senderDetailsState.Sender, isForUpdate, dispatch, history]);

  useEffect(() => {
    const addSenderAndRedirect = async () => {
      if (senderDetailsState.Sender !== null && senderDetailsState.Sender !== undefined && isForAdd) {
        if (bookingState.IsFromSignupKFS) {
          await dispatch(KFSSignupCustomer(senderDetailsState?.Sender));
        } else {
          await dispatch(AddSender(senderDetailsState?.Sender));
        }

        message.success('Sender added successfully');
        if (commonState.GlobalTransactionId) {
          history.push(PATHS.SenderListingBooking.path);
        } else if (transactionState?.FromUpdateLinkOrderABox && !transactionState.SelectedTransId) {
          history.push(PATHS.SenderListingOrderBox.path);
        } else if (transactionState?.FromUpdateLinkOrderABox) {
          history.push(PATHS.SummaryOrderBox.path);
        } else if (transactionState?.FromUpdateLink) {
          history.push('/transaction/summary');
        } else {
          history.push('/sender/listing');
        }
      }
    };

    addSenderAndRedirect();
  }, [senderDetailsState.Sender, isForAdd, dispatch, history]);

  const onFinish = async (values: any) => {
    dispatch(SenderDetailsActions.SetFirstSender(false));
    await dispatch(
      SenderDetailsActions.SetSender({
        ...values,
        // UserId: bookingState.FromSignupExistingUserId,
        UserId: commonState.GlobalSenderUserId,
      })
    );
    if (senderDetailsState.SenderStatus === SenderStatus.Update && transactionState?.FromUpdateLinkOrderABox) {
      setForUpdate(true);
    } else if (transactionState?.FromUpdateLinkOrderABox && !transactionState.SelectedTransId) {
      setForAdd(true);
      history.push(PATHS.SenderListingOrderBox.path);
    } else if (transactionState?.FromUpdateLinkOrderABox && transactionState.SelectedTransId) {
      setForUpdate(true);
      history.push(PATHS.SummaryOrderBox.path);
    } else if (senderDetailsState.SenderStatus === SenderStatus.Update || transactionState?.FromUpdateLink) {
      setForUpdate(true);
    } else if (senderDetailsState.SenderStatus === SenderStatus.New) {
      setForAdd(true);
    } else {
      history.push('/booking/receiver');
    }
  };
  const [dataSource, setDataSource] = useState<string[]>([]);
  const [dataSourceOriginal, setDataSourceOriginal] = useState<string[]>([]);

  // const [state1, setState1] = useState<string | null>(
  //   !transactionState?.FromUpdateLink
  //     ? senderDetailsState.Sender?.State
  //     : transactionState?.TransactionSummary?.Sender?.State
  // );

  const senderState =
    !transactionState || !transactionState.FromUpdateLink
      ? senderDetailsState?.Sender?.State
      : transactionState.TransactionSummary?.Sender?.State;

  const [state1, setState1] = useState<string | null>(senderState ?? null);
  const formRef = useRef<FormInstance>(null);
  const onSearch = (value: string) => {
    if (formRef.current) {
      // Clear the suburb value
      formRef.current.setFieldsValue({ Suburb: '' });
    }
    if (!value) {
      setError('This field is required');
      // You can choose to return or perform other actions based on the validation result
      return;
    }
    const results = getLocationDetailsByPostcode(Number(value));
    if (!results) {
      setError('Not found, please input the address manually.');
      // You can choose to return or perform other actions based on the validation result
      return;
    }

    const uniqueSuburbs = Array.from(new Set(results.map((result) => result.place_name)));

    const stat = Array.from(new Set(results.map((result) => result.state_code)));
    // console.log(stat[0] + 'ss');
    setState1(stat[0]);
    // console.log(state1);
    // console.log(uniqueSuburbs);
    // console.log(uniqueSuburbs);
    setDataSourceOriginal(uniqueSuburbs);
    setDataSource(uniqueSuburbs);
    setError('');
    if (uniqueSuburbs && uniqueSuburbs.length > 0) {
      if (formRef.current) {
        // Assuming "Suburb" is the name of the field you want to focus on
        formRef.current.getFieldInstance('Suburb').focus();
        formRef.current.setFields([{ name: 'Suburb', errors: ['Please enter suburb'] }]);
      }
    } else {
      if (formRef.current) {
        // Assuming "Suburb" is the name of the field you want to focus on
        formRef.current.getFieldInstance('Suburb').focus();
        formRef.current.setFields([
          { name: 'Suburb', errors: ['Postal code not found. Please enter address manually.'] },
        ]);
      }
    }
  };

  const [error, setError] = useState('');

  const handleChange = (e) => {
    const value = e.target.value;

    if (value) {
      setError(''); // Clear the error when there is a value
    } else {
      setError('This field is required');
      // You can choose to return or perform other actions based on the validation result
      return;
    }
  };

  const [selectedValue, setSelectedValue] = useState('');

  const handleSearch = (value) => {
    const filteredData = dataSource.filter((item) => item.toLowerCase().includes(value.toLowerCase()));
    setDataSource(filteredData);
  };

  const onSelect = (value) => {
    setSelectedValue(value);
  };

  const onClear = () => {
    // Reset the dataSource to the original list when cleared
    setDataSource(dataSourceOriginal);
  };

  const onFocus = () => {
    // Show the entire list when the input gains focus
    setDataSource(dataSourceOriginal);
  };

  const onKeyDown = (e) => {
    // Handle Backspace key to reset the list when the input becomes empty
    if (e.key === 'Backspace' || e.target.value.trim() === '') {
      setDataSource(dataSourceOriginal);
    }
  };
  const [form] = Form.useForm();
  // useEffect to handle updates based on conditions
  useEffect(() => {
    // Update state1 based on some condition if needed
    // Example: if (someCondition) setState1(newValue);

    // Set the initial value using setFieldsValue
    form.setFieldsValue({ State: state1 });
  }, [state1, transactionState, senderDetailsState, form]);

  const [showOthers, setShowOthers] = useState(false);

  const handleChangeHearus = (value) => {
    setShowOthers(value === 'OT');
  };
  return (
    <div className="App">
      {bookingState.IsFromSignupKFS ? (
        <Row justify="center" align="middle">
          <h1>KFS - Customer sign up</h1>
        </Row>
      ) : !transactionState?.FromUpdateLink ? (
        <Row justify="center" align="middle">
          {senderDetailsState.SenderStatus === SenderStatus.Update ? (
            <h1>Update sender</h1>
          ) : senderDetailsState.SenderStatus === SenderStatus.New ? (
            <h1>Add new sender</h1>
          ) : (
            <h1>{Constants.KFSBOX_BookPickupHeader}</h1>
          )}
        </Row>
      ) : (
        <Row justify="center" align="middle">
          <h1>Update sender</h1>
        </Row>
      )}
      <Form ref={formRef} name="senderForm" onFinish={onFinish} layout="vertical" form={form}>
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={8}>
            {!senderDetailsState.FirstSender && (
              <>
                <Breadcrumb
                  items={[
                    {
                      title: 'Sender',
                    },
                    {
                      title: 'Sender address',
                    },
                  ]}
                />
                <br />
              </>
            )}

            <Form.Item
              initialValue={
                !transactionState?.FromUpdateLink
                  ? senderDetailsState.Sender?.Address
                  : transactionState?.TransactionSummary?.Sender?.Address
              }
              label="Address"
              name="Address"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Input size="large" maxLength={100} />
            </Form.Item>
            <Form.Item
              initialValue={
                !transactionState?.FromUpdateLink
                  ? senderDetailsState.Sender?.PostalCode
                  : transactionState?.TransactionSummary?.Sender?.PostalCode
              }
              label="Postal code"
              name="PostalCode"
              validateStatus={error ? 'error' : ''}
              help={error}
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Search maxLength={10} enterButton size="large" onSearch={onSearch} onChange={handleChange} />
            </Form.Item>

            <Form.Item
              initialValue={
                !transactionState?.FromUpdateLink
                  ? senderDetailsState.Sender?.Suburb
                  : transactionState?.TransactionSummary?.Sender?.Suburb
              }
              label="Suburb"
              name="Suburb"
              rules={[{ required: true, message: suburbError }]}
            >
              <AutoComplete
                size="large"
                options={dataSource.map((item) => ({ value: item }))}
                onSelect={onSelect}
                onSearch={handleSearch}
                onClear={onClear}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                filterOption={(inputValue, option) =>
                  option?.value.toLowerCase().includes(inputValue.toLowerCase()) as boolean
                }
              >
                <Input size="large" maxLength={50} />
              </AutoComplete>
            </Form.Item>
            <Form.Item label="State" name="State" rules={[{ required: true, message: 'Please select a state' }]}>
              <Select
                size="large"
                showSearch
                placeholder="Select state"
                optionFilterProp="children"
                value={state1}
                onChange={(value) => setState1(value)}
                className="full-width"
              >
                {AUStateCodeList.map((destination) => (
                  <Select.Option key={destination.code} value={destination.code}>
                    {destination.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {bookingState.IsFromSignupKFS && senderDetailsState.FirstSender && (
              <>
                <Form.Item
                  label="How did you hear about us?"
                  name="HowDidYouHearAboutUs"
                  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                >
                  <Select
                    size="large"
                    showSearch
                    placeholder="Select"
                    optionFilterProp="children"
                    onChange={handleChangeHearus}
                  >
                    {SignUpHearUsCodeList.map((destination) => (
                      <Select.Option key={destination.code} value={destination.code}>
                        {destination.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                {showOthers && (
                  <Form.Item
                    label="Others"
                    name="Others"
                    rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                  >
                    <TextArea size="large" maxLength={500} rows={2} />
                  </Form.Item>
                )}
              </>
            )}
            <br />
            <div>
              <Button
                className="prevSenderBtn"
                size="large"
                type="default"
                onClick={() => history.push(senderDetailsState.FirstSender ? PATHS.Home.path : '/booking/sender')}
                loading={senderDetailsState.submitStatus == OperationStatus.pending}
              >
                {Constants.BTN_BACK}
              </Button>
              <Button
                className="nextSenderBtn"
                size="large"
                type="primary"
                htmlType="submit"
                loading={senderDetailsState.submitStatus == OperationStatus.pending}
              >
                {senderDetailsState.SenderStatus === SenderStatus.Update || transactionState?.FromUpdateLink
                  ? Constants.BTN_UPDATE
                  : senderDetailsState.SenderStatus === SenderStatus.New
                  ? Constants.BTN_SAVE
                  : Constants.BTN_CONTINUE}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
