import React, { useEffect, useState } from 'react';
import { Alert, Breadcrumb, Button, Col, Form, Input, Menu, message, Row, Select, Space, Tag, Tooltip } from 'antd';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Constants } from 'common/constants';
import { SignUpCustomer } from '../business/AccountBusiness';
import { RegisterViewModel } from 'common/models/UserTypes';
import { AccountSelector } from '../store/AccountSelector';
import { OperationStatus } from 'store/rootTypes';
import { PATHS } from '@routing/routes';
import '@features/Account/Account.css';
import { MobileCountryCodeList } from 'common/code/MobileCountryCode';
import { handleFocusContactNumber, handleKeyDownContactNumberNew } from 'common/utils/utility';
import TextArea from 'antd/es/input/TextArea';
import { SignUpHearUsCodeList } from 'common/code/DropdownCode';
export const SignUp = () => {
  const dispatch = useDispatch();
  const accountState = useSelector(AccountSelector);
  const [message, setMessage] = useState<any>('');
  const [isSuccess, setIsSuccess] = useState(false);

  const [referralCode, setReferral] = useState('');
  const [storeIdReferralCode, setStoreIdReferral] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      const token = new URLSearchParams(location.search).get('token');
      const storeId = new URLSearchParams(location.search).get('storeId');
      // alert(storeId);
      if (token) {
        setReferral(token);
      }
      if (storeId) {
        setStoreIdReferral(storeId);
        form.setFieldsValue({ HowDidYouHearAboutUs: 'AS' });
      }
    };
    fetchData();
  }, [dispatch, location.search]);

  const onFinish = async (values: RegisterViewModel) => {
    setMessage('');
    setIsSuccess(false);
    const updatedValues = {
      ...values,
      ReferralCode: referralCode,
      MobileCode: selectedMobileCode,
      StoreId: storeIdReferralCode,
    };
    const data = await dispatch(SignUpCustomer(updatedValues));
    if (data && data != undefined) {
      setMessage(data);
      setIsSuccess(false);
    } else {
      setMessage('');
      setIsSuccess(true);
      form.resetFields();
    }
  };
  const [form] = Form.useForm();

  const validatePassword = (_, value) => {
    if (!value) {
      return Promise.reject('This field is required');
    }
    if (!Constants.PASSWORD_REGEX.test(value)) {
      const informationMessage = (
        <div>
          <p className="pHeader">Passwords should be a minimum of 8 characters long and contain a mix of:</p>
          <ul className="pBottom">
            <li>numbers</li>
            <li>uppercase and lowercase letters</li>
            <li>special characters {Constants.ERROR_PASSWORD_ssFORMAT}</li>
          </ul>
        </div>
      );
      return Promise.reject(<Alert description={informationMessage} type="error" />);
    }

    return Promise.resolve();
  };

  const [selectedMobileCode, setSelectedMobileCode] = useState('+61');
  const { Option } = Select;

  const selectStyle = { width: '100px' }; // Adjust the width as needed
  const inputStyle = { width: 'calc(100% - 100px)' }; // Adjust the width based on the select width

  const [showOthers, setShowOthers] = useState(false);

  const handleChange = (value) => {
    setShowOthers(value === 'OT');
  };
  return (
    <div className="App">
      <Row justify="center" align="middle">
        <h4>Sign up for {Constants.KFS_PAGE_TITLE}</h4>
      </Row>

      <Form name="senderForm" onFinish={onFinish} layout="vertical" form={form}>
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={8}>
            <br />
            <Form.Item
              label="Given name"
              name="GivenName"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Input size="large" maxLength={25} />
            </Form.Item>
            <Form.Item label="Surname" name="Surname" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
              <Input size="large" maxLength={25} />
            </Form.Item>
            <Form.Item label="Contact number" name="ContactNo">
              <Input.Group compact>
                <Form.Item
                  noStyle // Adjust the name prop for the Select component
                  initialValue="+1"
                >
                  <Select
                    style={selectStyle}
                    value={selectedMobileCode}
                    onChange={(value) => setSelectedMobileCode(value)}
                    size="large" // Add your onChange logic
                    showSearch // Enable search
                    optionFilterProp="children" // Use the "children" property for filtering
                  >
                    {MobileCountryCodeList.map((country) => (
                      <Option key={country.code} value={country.dial_code}>
                        {`${country.dial_code}`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  noStyle
                  name="ContactNo"
                  initialValue=""
                  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                >
                  <Input
                    style={inputStyle}
                    placeholder="Mobile number"
                    size="large"
                    onKeyDown={handleKeyDownContactNumberNew}
                    onFocus={handleFocusContactNumber}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <Form.Item label="Postcode" name="Postcode" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
              <Input size="large" maxLength={150} />
            </Form.Item>

            <Form.Item
              label="Email"
              name="Username"
              rules={[
                { required: true, message: Constants.REQUIRED_FIELD },
                {
                  pattern: Constants.EMAIL_REGEX_INPUT,
                  message: 'Please enter a valid email address.',
                },
              ]}
            >
              <Input size="large" maxLength={150} />
            </Form.Item>
            <Form.Item label="Password" name="Password" rules={[{ validator: validatePassword }]}>
              <Input.Password size="large" maxLength={150} type="password" />
            </Form.Item>

            <Form.Item
              label="How did you hear about us?"
              name="HowDidYouHearAboutUs"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Select size="large" showSearch placeholder="Select" optionFilterProp="children" onChange={handleChange}>
                {SignUpHearUsCodeList.map((destination) => (
                  <Select.Option key={destination.code} value={destination.code}>
                    {destination.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {showOthers && (
              <Form.Item label="Others" name="Others" rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}>
                <TextArea size="large" maxLength={500} rows={2} />
              </Form.Item>
            )}
            <div>
              {isSuccess && (
                <Alert
                  message="Account created successfully. Please check your email to confirm your account."
                  type="success"
                  showIcon
                />
              )}

              {message && <Alert message={message} type="error" showIcon />}

              <br />
              <Button
                className="nextSenderBtn full-width"
                size="large"
                type="primary"
                htmlType="submit"
                loading={accountState.submitStatus == OperationStatus.pending}
              >
                Create account
              </Button>
            </div>

            <br />
            <br />
            <br />
            <Row justify="center" align="middle">
              <Col>
                Already have an account?{' '}
                <Link to={PATHS.Login.path} className="normal-font">
                  Log in
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
