import { Card, Col, Row, Space, Steps, Input, Button, Modal } from 'antd';
import '@features/Webpage/homepage.css';
import {
  KFSpartnerstoreicon,
  boxIconExport,
  businessFreighIcon,
  containerIcon,
  luzonIcon,
  minIcon,
  mnlIcon,
  phoneIconExport,
  referred,
  visIcon,
  webpageheader,
} from 'common/images';
import { Link, useHistory } from 'react-router-dom';
import { PATHS } from '@routing/routes';
import { Constants, TransactionStatus, TransactionType } from 'common/constants';
import { WebsiteFooter } from './Footer';
import { useEffect, useState } from 'react';
const { Step } = Steps;
const { Search } = Input;
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
  SolutionOutlined,
  ClockCircleOutlined,
  FileProtectOutlined,
  HomeOutlined,
  GlobalOutlined,
  InsertRowBelowOutlined,
  CodeSandboxOutlined,
  BankOutlined,
  MergeCellsOutlined,
} from '@ant-design/icons';
import { FaTruckLoading, FaTruck, FaTruckMoving, FaTruckMonster, FaTruckPickup } from 'react-icons/fa';
import { FaDocker } from 'react-icons/fa';
import { TrackingStatusResponse } from 'common/models';
import { useDispatch, useSelector } from 'react-redux';
import { GetTrackingStatus, TrackingSelector } from '@features/Tracking';
import { PostStart } from '@features/Common';
export const Homepage = () => {
  // Adjust the condition based on your requirement
  const history = useHistory();

  const [searchValue, setSearchValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [fromSearchValue, setFromSearch] = useState(false);
  const dispatch = useDispatch();
  const trackingState = useSelector(TrackingSelector);
  const [data, setData] = useState<TrackingStatusResponse[]>([]);
  // let data: TrackingStatusResponse[] = [];
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    // Hide the error message when the user starts typing
    setErrorMessage('');
  };
  const [trackingLabel, setTrackingLabel] = useState('');
  const handleSearchSubmit = async () => {
    setFromSearch(true);
    setData([]);
    if (searchValue.trim() !== '') {
      // Perform your action when the search is not empty
      await dispatch(GetTrackingStatus(searchValue.trim(), true));
      // const updatedData = trackingState.TrackingStatusResponse;
    } else {
      // Set the error message when the search is empty
      setErrorMessage(Constants.REQUIRED_FIELD);
    }
  };

  useEffect(() => {
    if (fromSearchValue) {
      setData(trackingState.TrackingStatusResponse);

      // Check if the data is empty and update the error message accordingly
      if (trackingState.TrackingStatusResponse.length === 0) {
        setErrorMessage('Record not found');
      } else {
        setModalVisible(true);
        setErrorMessage('');
      }
    }
  }, [trackingState.TrackingStatusResponse]);

  const [modalVisible, setModalVisible] = useState(false);

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    setTrackingLabel(data[0]?.Type == TransactionType.OrderBox ? 'Tracking - Empty box order' : 'Tracking');
  }, [data[0]?.Type]);
  const handleAgree = () => {
    // Perform actions when the user agrees (e.g., update state, submit form)
    // You can add your custom logic here
    setModalVisible(false); // Close the modal
  };

  useEffect(() => {
    dispatch(PostStart());
  }, []);
  return (
    <>
      <Modal
        title=""
        visible={modalVisible}
        onCancel={handleCloseModal}
        footer={
          [
            // <Button key="agree" type="default" onClick={handleAgree} className="prevSenderBtn">
            //   Close
            // </Button>,
          ]
        }
      >
        <div className="scrollable-modal-content-TOC">
          <Row justify="center" align="middle">
            <h2>{trackingLabel}</h2>
          </Row>

          <br />
          <Row justify="center" align="middle">
            <Col>
              <Steps direction="vertical" current={10}>
                {data.map((step, index) => {
                  return (
                    <Step
                      key={index}
                      title={
                        <>
                          <span dangerouslySetInnerHTML={{ __html: step.Description }} />
                        </>
                      }
                      icon={getIcon(step.Status)}
                      // description={step.Date}
                      description={
                        <>
                          <div>{step.Date}</div>
                          {step.TrackingNotes ? (
                            <div>
                              <span dangerouslySetInnerHTML={{ __html: step.TrackingNotes }} />
                            </div>
                          ) : null}
                        </>
                      }
                    />
                  );
                })}
              </Steps>
              <Button className="prevSenderBtn full-width " size="large" type="default" onClick={handleCloseModal}>
                {Constants.BTN_CLOSE}
              </Button>{' '}
            </Col>
          </Row>
        </div>
      </Modal>
      <Row gutter={0} align="middle" className="headerMainRow mainHeaderSmall">
        <Col xs={24} sm={8} md={8} lg={6} xl={4} className="webPageHeaderText flex-container-s">
          <p className="pWebPageHeaderTextSmall"> Send your balikbayan box from Australia to the Philippines</p>
        </Col>
      </Row>
      <Row gutter={0} align="middle" className="headerMainRow">
        <Col xs={24} sm={16} md={16} lg={18} xl={20} className="flex-container-s">
          <img src={webpageheader} alt="Header" className="full-width webpageheaderClass" />
        </Col>
        <Col xs={24} sm={8} md={8} lg={6} xl={4} className="webPageHeaderText flex-container-s mainHeaderHide">
          {/* Content for the second column (1 sentence text) */}
          <p className="pWebPageHeaderText"> Send your</p>
          <p className="pWebPageHeaderText"> balikbayan box</p>
          <p className="pWebPageHeaderText"> from Australia</p>
          <p className="pWebPageHeaderText"> to the</p>
          <p className="pWebPageHeaderText"> Philippines</p>
        </Col>
      </Row>
      <br />
      {/* Second Row with Feature Columns */}
      <div className="border-sendus">
        <Row gutter={16} justify="center" align="middle">
          <Col xs={24} sm={12} md={12} lg={10} xl={8} className="tracking-div">
            <p className="textColor mb-0 ">
              <b>Track your shipment</b>{' '}
            </p>
            <Search
              maxLength={10}
              enterButton
              size="large"
              value={searchValue}
              onChange={handleSearchChange}
              onPressEnter={handleSearchSubmit}
              onSearch={handleSearchSubmit}
            />
            {errorMessage && <span className="red">{errorMessage}</span>}
          </Col>
        </Row>
        <Row gutter={16} justify="center" align="middle">
          <Col xs={24} sm={12} md={12} lg={6} xl={6} className="howItWorksCol2">
            <br />
            <Space>
              <img src={phoneIconExport} className="phoneIcon" />
              <Link to={PATHS.Login.path} className="normal-font underline">
                Sign up or book online
              </Link>
            </Space>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={7} className="howItWorksCol2">
            <br />
            <Space>
              <img src={KFSpartnerstoreicon} />
              <Link to={PATHS.KFSStorePartnerMel.path} className="normal-font  underline">
                Buy a box from our partner stores
              </Link>
            </Space>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6} className="howItWorksCol2">
            <br />
            <Space>
              <img src={boxIconExport} />
              <Link to={PATHS.PricingMel.path} className="normal-font  underline">
                View our rates
              </Link>
            </Space>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={5} className="">
            <br />
            <Space>
              <img src={referred} />
              <Link to={PATHS.ContactUs.path} className="normal-font  underline">
                Contact us
              </Link>
            </Space>
          </Col>
        </Row>
      </div>
      <br />

      <Row gutter={16}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h3>Why KFS?</h3>
          <ul>
            <li className="liWhyKFS">
              <b>Customer-centric service.</b> Grounded in the Filipino value of <i>Kalinga or care</i>, our commitment
              is to provide the best assistance you need.
            </li>
            <br />
            <li className="liWhyKFS">
              <b>Reliability unleashed.</b> We have the experience and expertise in the industry to ensure that
              balikbayan boxes are delivered to your loved ones.
            </li>
            <br />
            <li className="liWhyKFS">
              <b>Shipment tracking made easy.</b> We use the latest technology to provide our customers with information
              about the progress of the shipment.
            </li>
            <br />
            <li className="liWhyKFS">
              <b>Empowering businesses.</b> We are your total logistics solution. Beyond balikbayan boxes, we&apos;re
              here to support you in achieving your business objectives.
            </li>
          </ul>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h3 className="howItWorksCol2">How it works?</h3>
          <div className="howItWorksCol">
            <Steps direction="vertical">
              <Step title="Create an online account" className="custom-step" />
              <Step title={Constants.KFSBOX_BookPickupHeader} className="custom-step" />
              <Step title="We'll call you to confirm the date and time" className="custom-step" />
              <Step title="We’ll pick up your balikabayan boxes" className="custom-step" />
              <Step title="Track your balikbayan boxes" className="custom-step" />
            </Steps>
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h3>Some perks for you!</h3>
          <div>
            <ul>
              <li className="liWhyKFS">Get a $10 credit when you sign up online.</li>
              <br />
              <li className="liWhyKFS">Refer your friends, and you both get a $10 discount on your shipping fees.</li>
              <br />
              <li className="liWhyKFS">Send 10 balikbayan boxes, and ship the next box free of charge.</li>
            </ul>
            <a onClick={() => history.push(PATHS.TermsAndConditionRewards.path)} className="tAndClink">
              Terms and conditions of our perks
            </a>
          </div>
          {/* <h3>Let’s get started!</h3>
          <Steps direction="vertical">
            <Step
              title={
                <Link to={PATHS.Login.path} className="letsGetStarted">
                  Click here to create an account or schedule a pick up
                </Link>
              }
              className="custom-step custom-step1 "
              icon={<img src={phoneIconExport} alt="Step 1" className="phoneIcon" />}
            />

            <Step
              title={
                <Link to={PATHS.ContactUs.path} className="letsGetStarted">
                  Let’s talk about other services that you need
                </Link>
              }
              className="custom-step custom-step1 "
              icon={<img src={letsTalkIconExport} alt="Step 2" />}
            />
            <Step
              title="Find where to get an empty KFS balikbayan box"
              className="custom-step custom-step1 "
              icon={<img src={boxIconExport} alt="Step 2" />}
            />
          </Steps> */}
        </Col>
      </Row>

      <Row gutter={24}>
        <Col
          xs={{ span: 24, order: 2 }}
          sm={{ span: 24, order: 2 }}
          md={{ span: 24, order: 1 }}
          lg={{ span: 24, order: 1 }}
          xl={{ span: 24, order: 1 }}
        >
          <h3>Our services</h3>
          <Row justify="space-between">
            <Col sm={12} className="text-left">
              <b className="textColorPrimary"> The Kalinga balikbayan boxes</b>
            </Col>
            <Col sm={12} className="text-right">
              <div className="floatRight">
                {/* <Link to={PATHS.PricingMel.path} className="normal-font underline">
                  view our rates
                </Link>
                <span className="normal-font dInlineBlock verticalBar"> | </span> */}
                <Link to={PATHS.Balikbayanbox.path} className="normal-font underline">
                  view box dimensions
                </Link>
              </div>
            </Col>
          </Row>
          <br></br>
          <Row gutter={24}>
            <Col xs={19} sm={12} md={12} lg={8} xl={6} className="serviceCol">
              <Card
                title={
                  <div>
                    Biyaheng Metro Manila <br />
                  </div>
                }
                cover={<img alt="example" src={mnlIcon} className="serviceImg" />}
              ></Card>
            </Col>
            <Col xs={19} sm={12} md={12} lg={8} xl={6} className="serviceCol">
              <Card
                title={
                  <div>
                    Biyaheng Luzon <br />
                  </div>
                }
                cover={<img alt="example" src={luzonIcon} className="serviceImg" />}
              ></Card>
            </Col>
            <Col xs={19} sm={12} md={12} lg={8} xl={6} className="serviceCol">
              <Card
                title={
                  <div>
                    Biyaheng Visayas <br />
                  </div>
                }
                cover={<img alt="example" src={visIcon} className="serviceImg" />}
              ></Card>
            </Col>
            <Col xs={19} sm={12} md={12} lg={8} xl={6} className="serviceCol">
              <Card
                className="cardService"
                title={
                  <div>
                    Biyaheng Mindanao <br />
                  </div>
                }
                cover={<img alt="example" src={minIcon} className="serviceImg" />}
              ></Card>
            </Col>
          </Row>
        </Col>

        {/* Second Column with Text */}
        {/* <Col
          xs={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
          md={{ span: 8, order: 2 }}
          lg={{ span: 8, order: 2 }}
          xl={{ span: 8, order: 2 }}
        >
          <h3>Some perks for you!</h3>
          <div>
            <ul>
              <li className="liWhyKFS">Get a $10 credit when you sign up online.</li>
              <br />
              <li className="liWhyKFS">Refer your friends, and you both get a $10 discount on your shipping fees.</li>
              <br />
              <li className="liWhyKFS">Send 10 balikbayan boxes, and ship the next box free of charge.</li>
            </ul>
            <a onClick={() => history.push(PATHS.TermsAndConditionRewards.path)} className="tAndClink">
              Terms and conditions of our perks
            </a>
          </div>
        </Col> */}
      </Row>
      <br />
      <br />
      <Row gutter={16}>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <h3 className="balibayantext">The returning residents</h3>
          <Card className="cardOurservice">
            <img alt="example" src={containerIcon} className="service-import" />
            <div className="pl-10">
              <p className="textColor">
                Whether you&apos;re relocating your white goods or entire home contents, we&apos;ve got you covered.
                Explore our Full Container Load (FCL) options, including 20-footer and 40-footer containers, for a
                seamless and secure transportation experience. Opt for flexibility with our Less Container Load (LCL)
                services, ensuring cost-effectiveness for smaller shipments. Your cargo, our commitment – delivering
                tailored solutions to meet your unique requirements.
              </p>
              {/* <p className="bold textColor">
                {' '}
                We can arrange a whole container for your white goods or all your home contents.
              </p>

              <ul className="">
                <li className="liWhyKFS textColor">Full container load (FCL)</li>
                <p className="textColor mtb"> - 20 footer container</p>
                <p className="textColor mtb"> - 40 footer container</p>
              </ul>
              <ul className="">
                <li className="liWhyKFS textColor">Less container load (LCL)</li>
              </ul>
              <p className="textColor"> </p> */}
              <Link to="/contactus" className="normal-font floatRight pb-10 pr-10">
                Let’s discuss your options {'>>>'}
              </Link>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <h3 className="balibayantext Businessfreight">Business freight solutions</h3>
          <Card className="cardOurservice">
            <img alt="example" src={businessFreighIcon} className="service-import" />
            <div className="pl-10">
              <p className="textColor">
                Navigating the world of international commerce with expertise, we specialise in the import and export of
                commercial goods on a global scale. From streamlined imports and exports to efficient freight
                forwarding, secure warehousing, and seamless local distribution, we offer comprehensive solutions
                tailored to your business needs. Elevate your global trade experience with our expertise in handling
                every aspect of the supply chain.
              </p>
              {/* <p className="bold textColor">Commercial goods for import and export globally.</p>
              <div className="pleftCom">
                <p className="textColor  mtb-0 pleftCom"> Imports and exports</p>
                <ul className="mtp-0">
                  <li className="liWhyKFS "> Freight forwarding</li>
                  <li className="liWhyKFS ">Warehousing</li>
                  <li className="liWhyKFS ">Local distribution</li>
                </ul>
              </div> */}
              <Link to="/contactus" className="normal-font floatRight pb-6 pr-10">
                Let’s talk about your business {'>>>'}
              </Link>
            </div>
          </Card>
        </Col>
      </Row>
      {/* <br />
      <h3 className="balibayantext">The returning residents a.k.a balikbayan</h3>
      <Row gutter={16} className="boder-balibayantext">
        <Col xs={24} sm={8} md={12} lg={12} xl={12} className="BusinessfreightDiv">
          <p className="bold balibayantext textColor">
            {' '}
            We can arrange a whole container for your white goods or all your home contents.
          </p>

          <ul className="">
            <li className="liWhyKFS textColor">Full container load (FCL)</li>
            <p className="textColor mtb"> - 20 footer container</p>
            <p className="textColor mtb"> - 40 footer container</p>
          </ul>
          <ul className="">
            <li className="liWhyKFS textColor">Less container load (LCL)</li>
          </ul>
          <p className="textColor"> </p>
          <Link to="/contactus" className="normal-font floatRight">
            Let’s discuss your options {'>>>'}
          </Link>
        </Col>
        <Col xs={24} sm={16} md={12} lg={12} xl={12} className="containerIconCol">
          <img src={containerIcon} alt="Header" className="full-width heightAKABalikbayan" />
        </Col>
      </Row>
      <br />
      <h3 className="balibayantext">Business freight solutions</h3>
      <Row gutter={0} align="middle" className="headerMainRow">
        <Col xs={24} sm={16} md={12} lg={12} xl={12} className="flex-container-s">
          <img src={businessFreighIcon} alt="Header" className="full-width heightBusinessFreight" />
        </Col>
        <Col xs={24} sm={8} md={12} lg={12} xl={12} className="BusinessfreightDiv">
          <p className="bold balibayantext commercialTxt">Commercial goods for import and export globally.</p>
          <div className="pleftCom">
            <p className="textColor commercialTxt mtb-0 pleftCom"> Importation</p>
            <ul className="mtp-0">
              <li className="liWhyKFS commercialTxt"> Freight forwarding</li>
              <li className="liWhyKFS commercialTxt">Warehousing</li>
              <li className="liWhyKFS commercialTxt">Local distribution</li>
            </ul>
          </div>
          <Link to="/contactus" className="normal-font floatRight commercialTxt talkBusiness">
            Let’s talk about your business {'>>>'}
          </Link>
        </Col>
      </Row> */}
      <br />
      <Row gutter={0} align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <WebsiteFooter />
        </Col>
      </Row>
    </>
  );
};

const getIcon = (code: string) => {
  switch (code) {
    case TransactionStatus.NW: //New
      return <UserOutlined />;
    case TransactionStatus.PI: //For Pick up	For Pick up - [location]
      return <ClockCircleOutlined />;
    case TransactionStatus.ITRA: //In-Transit	Received - with KFS agent
      return <FileProtectOutlined />;
    case TransactionStatus.ITIS: //In-Transit	- Interstate
      return <MergeCellsOutlined />;
    case TransactionStatus.ITD: //In-Transit	Departed - [location]
      return <GlobalOutlined />;
    case TransactionStatus.ITAD: //In-Transit	Arrived - [location] transhipment port
      return <InsertRowBelowOutlined />;
    case TransactionStatus.ITDDP: //In-Transit	Departed - [location] transhipment port to Manila, PH
      return <GlobalOutlined />;
    case TransactionStatus.ITWHIPH: //In-Transit	Arrived - Manila port
      return <BankOutlined />;
    case TransactionStatus.ITADP: //In-Transit	Arrived - Manila port
      return <InsertRowBelowOutlined />;
    case TransactionStatus.ITWD: //In-Transit	Warehouse to destination - estimated delivery : [date]
      return <HomeOutlined />;
    case TransactionStatus.DE: //Delivered	Delivered - received by [name]
      return <CodeSandboxOutlined />;
    case TransactionStatus.CA: //Cancelled
      return <CloseCircleOutlined />;
    default:
      return <FaTruckLoading />;
  }
};
