import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Space, Spin, Tag, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import styles from 'features/SenderDetails/Sender.module.less';
import { BookingStatus, Constants, ReceiverStatus, SenderStatus } from 'common/constants';
import { GetAllSender, GetSenderById } from '../business/SenderDetailsBusiness';
import { SenderModel } from 'common/models/BookingTypes';
import { useDispatch, useSelector } from 'react-redux';
import { SenderDetailsActions } from '@features/SenderDetails/store/SenderDetailsSlice';
import { ReceiverActions } from '@features/Receiver';
import { SenderDetailsSelector } from '../store/SenderDetailsSelector';
import { BookingSelector } from '@features/Booking';
const { CheckableTag } = Tag;

export const SenderListing = () => {
  const senderDetailsState = useSelector(SenderDetailsSelector);
  const bookingState = useSelector(BookingSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleChange = (userId: string) => {
    dispatch(SenderDetailsActions.SetSelectedId(userId));
  };

  const [data, setData] = useState<SenderModel[]>([]);

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const result = await GetAllSender(bookingState.FromSignupExistingUserId);

        if (
          result &&
          result.length > 0 &&
          (!senderDetailsState.SelectedId || senderDetailsState.SelectedId.trim() === '')
        ) {
          // Check if result is not null or undefined and SelectedId is undefined or empty

          if (result[0]?.Address == null) {
            await dispatch(GetSenderById(result[0]?.Id));
            dispatch(SenderDetailsActions.SetSenderStatus(SenderStatus.Update));
            dispatch(SenderDetailsActions.SetSelectedId(result[0]?.Id));
            dispatch(SenderDetailsActions.SetFirstSender(true));
            history.push('/booking/senderaddress');
          }
          dispatch(SenderDetailsActions.SetSelectedId(result[0]?.Id));
        }
        setData(result);
      } catch (error) {
        // Handle error as needed
      }
    };
    fetchDataAsync();
  }, []);

  const onFinish = async (values: any) => {
    if (senderDetailsState.SelectedId === undefined) {
      message.warning('Please add a sender for this transaction.');
      return;
    }
    await dispatch(GetSenderById(senderDetailsState?.SelectedId));
    dispatch(SenderDetailsActions.SetSenderStatus(SenderStatus.Booking));
    dispatch(ReceiverActions.SetReceiverStatus(ReceiverStatus.Booking));
    history.push('/booking/sender');
  };

  const onUpdate = async (value: any) => {
    if (value === null) return;
    await dispatch(GetSenderById(value));
    dispatch(SenderDetailsActions.SetSenderStatus(SenderStatus.Update));
    dispatch(SenderDetailsActions.SetSelectedId(value));
    history.push('/booking/sender');
  };

  const AddNewSender = async () => {
    dispatch(SenderDetailsActions.resetState());
    dispatch(SenderDetailsActions.SetSenderStatus(SenderStatus.New));
  };

  return (
    <div className="App">
      <Form name="senderForm" layout="vertical" onFinish={onFinish}>
        <Row justify="center" align="middle">
          <h1>Sender details</h1>
        </Row>

        {data ? (
          <Row justify="center" align="middle">
            <Col>
              <Space wrap direction="vertical">
                {data.map((sender) => (
                  <div key={sender.Id} className={styles.FlexColumnContainer}>
                    <Row justify="center" align="middle">
                      <Col span={24} className={`${styles.rightText}`}>
                        <a className={styles.link} onClick={() => onUpdate(sender.Id)}>
                          Update
                        </a>
                      </Col>

                      <Col span={24}>
                        <CheckableTag
                          checked={sender.Id === senderDetailsState.SelectedId}
                          onChange={() => handleChange(sender.Id)}
                          className={styles.anttagcheckable}
                        >
                          <div>
                            <div>
                              {sender.GivenName} {sender.Surname}
                            </div>
                            <div>
                              {sender.Address || sender.Suburb || sender.State ? (
                                <>
                                  {sender.Address && <span>{sender.Address}, </span>}
                                  {sender.Suburb && <span>{sender.Suburb}, </span>}
                                  {sender.State && <span>{sender.State}, </span>}
                                  {sender.PostalCode}
                                </>
                              ) : (
                                <>{sender.PostalCode}</>
                              )}
                            </div>
                            <div>{sender.Email}</div>
                            <div>
                              {sender.MobileCode} {sender.ContactNo}
                            </div>
                          </div>
                        </CheckableTag>
                      </Col>
                    </Row>
                  </div>
                ))}
              </Space>
            </Col>
          </Row>
        ) : (
          <Spin size="large" />
        )}

        <br />
        <Row justify="center" align="middle">
          <Col>
            <Link to="/booking/sender" className="normal-font" onClick={AddNewSender}>
              Add new sender
            </Link>
          </Col>
        </Row>
        <br />
        {bookingState.IsSendABox ? (
          <Row justify="center" align="middle">
            <Col xs={24} sm={8} md={14} lg={10} xl={7} xxl={5}>
              <Button className="prevSenderBtn" size="large" type="default" onClick={() => history.push('/home')}>
                {Constants.BTN_CLOSE}
              </Button>
              <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
                {Constants.BTN_CONTINUE}
              </Button>
            </Col>
          </Row>
        ) : (
          <Row justify="center" align="middle">
            <Col xs={24} sm={8} md={14} lg={10} xl={7} xxl={5}>
              <Button
                className="prevSenderBtn full-width"
                size="large"
                type="default"
                onClick={() => history.push('/home')}
              >
                {Constants.BTN_CLOSE}
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};
